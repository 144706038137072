var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        okButtonProps: { props: { disabled: _vm.disableSubmit } },
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.model, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "航班号",
                    prop: "ticketCode",
                    hasFeedback: ""
                  }
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "请输入航班号",
                      readOnly: _vm.disableSubmit
                    },
                    model: {
                      value: _vm.model.ticketCode,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "ticketCode", $$v)
                      },
                      expression: "model.ticketCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "航班时间",
                    prop: "tickectDate",
                    hasFeedback: ""
                  }
                },
                [
                  _c("j-date", {
                    model: {
                      value: _vm.model.tickectDate,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "tickectDate", $$v)
                      },
                      expression: "model.tickectDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单号码",
                    hidden: _vm.hiding,
                    hasFeedback: ""
                  },
                  model: {
                    value: this.orderId,
                    callback: function($$v) {
                      _vm.$set(this, "orderId", $$v)
                    },
                    expression: "this.orderId"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { disabled: "disabled" },
                    model: {
                      value: _vm.model.orderId,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "orderId", $$v)
                      },
                      expression: "model.orderId"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "创建人",
                    hidden: _vm.hiding,
                    hasFeedback: ""
                  }
                },
                [
                  _c("a-input", {
                    attrs: { readOnly: _vm.disableSubmit },
                    model: {
                      value: _vm.model.createBy,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "createBy", $$v)
                      },
                      expression: "model.createBy"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "创建时间",
                    hidden: _vm.hiding,
                    hasFeedback: ""
                  }
                },
                [
                  _c("a-input", {
                    attrs: { readOnly: _vm.disableSubmit },
                    model: {
                      value: _vm.model.createTime,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "createTime", $$v)
                      },
                      expression: "model.createTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }