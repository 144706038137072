var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("j-vxe-table", {
    ref: "vTable",
    staticStyle: { "margin-top": "8px" },
    attrs: {
      toolbar: "",
      "row-number": "",
      "row-selection": "",
      "keep-source": "",
      height: 484,
      loading: _vm.loading,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      pagination: _vm.pagination
    },
    on: { pageChange: _vm.handlePageChange },
    scopedSlots: _vm._u([
      {
        key: "toolbarSuffix",
        fn: function() {
          return [
            _c("a-button", { on: { click: _vm.handleTableGet } }, [
              _vm._v("获取值")
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }