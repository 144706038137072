var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "组织名称",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "name"
                          }
                        },
                        [
                          _c("a-input", {
                            ref: "input",
                            model: {
                              value: _vm.model.name,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "name", $$v)
                              },
                              expression: "model.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "大学名称",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "schoolIdCode"
                          }
                        },
                        [
                          _c("a-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "default-active-first-option": false,
                              "filter-option": false,
                              "not-found-content": null,
                              options: _vm.schoolData,
                              "show-arrow": false,
                              placeholder: "请输入组织名称",
                              "show-search": ""
                            },
                            on: {
                              search: _vm.handleSearch,
                              change: _vm.roomChange
                            },
                            model: {
                              value: _vm.model.schoolIdCode,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "schoolIdCode", $$v)
                              },
                              expression: "model.schoolIdCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "机构代码",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              min: 1,
                              placeholder: "请输入机构代码",
                              disabled: true
                            },
                            model: {
                              value: _vm.model.schoolIdCode,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "schoolIdCode", $$v)
                              },
                              expression: "model.schoolIdCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.model.id
                    ? _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "组织编号",
                                labelCol: _vm.labelCol,
                                wrapperCol: _vm.wrapperCol,
                                prop: "id"
                              }
                            },
                            [
                              _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  min: 1,
                                  placeholder: "请输入组织编号",
                                  disabled: _vm.disabledId
                                },
                                model: {
                                  value: _vm.model.id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "id", $$v)
                                  },
                                  expression: "model.id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "角色编码前缀",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "preCode"
                          }
                        },
                        [
                          _c("a-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请输入角色编码前缀",
                              disabled: _vm.disabledId
                            },
                            model: {
                              value: _vm.model.preCode,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "preCode", $$v)
                              },
                              expression: "model.preCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "开始时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择开始时间",
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.model.beginDate,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "beginDate", $$v)
                              },
                              expression: "model.beginDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "结束时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择结束时间",
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.model.endDate,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "endDate", $$v)
                              },
                              expression: "model.endDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              attrs: { name: "tenantStatus" },
                              model: {
                                value: _vm.model.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "status", $$v)
                                },
                                expression: "model.status"
                              }
                            },
                            [
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v("正常")
                              ]),
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v("冻结")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showFlowSubmitButton
                    ? _c(
                        "a-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 24 }
                        },
                        [
                          _c("a-button", { on: { click: _vm.submitForm } }, [
                            _vm._v("提 交")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }