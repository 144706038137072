var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-tabs",
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "基础示例", forceRender: "" } },
            [_c("j-vxe-demo1")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "高级示例", forceRender: "" } },
            [_c("j-vxe-demo2")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "联动示例", forceRender: "" } },
            [_c("j-vxe-demo3")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }