<template>
  <div class="login_main">
    <!-- <div style="margin-top: -100px;">
        
    </div> -->
    <div style="height: 100%; display: flex; justify-content: center; align-items: center">
      <div class="imgBox">
        <img class="img" src="../../assets/newIndex/logo_left.png" alt="logo" />
        <img class="img_top" @click="gotoIndex" src="../../assets/newIndex/footer_logo.png" alt="logo" />
      </div>
      <div class="loginBox">
        <div
          class="main rightBox"
          style="width: 100%; min-height: 350px; display: flex; justify-content: center; align-items: center"
        >
          <div class="main rightBox">
            <!-- <div class="qrcode">
            <h1>生成二维码</h1>
            <label for="text">请输入要转换为二维码的内容：</label>
            <input type="text" id="text" v-model="text" @keyup.enter="generateQRCode"/>
            <button @click="generateQRCode">生成</button>
            <div ref="qrcode" style="margin:0 auto;"></div>
          </div> -->
            <div class="title">欢迎使用</div>
            <a-form-model class="user-layout-login" @keyup.enter.native="handleSubmit">
              <login-account
                ref="alogin"
                @validateFail="validateFail"
                @success="requestSuccess"
                @fail="requestFailed"
              ></login-account>
              <!--<a-tab-pane key="tab2" tab="手机号登录">
                <login-phone ref="plogin" @validateFail="validateFail" @success="requestSuccess" @fail="requestFailed"></login-phone>
              </a-tab-pane>-->
              <a-form-model-item>
                <a-checkbox @change="handleRememberMeChange" default-checked>自动登录</a-checkbox>
                <!-- <router-link :to="{ name: 'alteration' }" class="forge-password" style="float: right">
                教师账号注册
              </router-link>
              <a href="javascript:;" @click="stuRrgister">学生账户注册</a> -->
                <!-- <router-link :to="{ name: 'register' }" class="forge-password" style="float: right; margin-right: 10px">
                学生账户注册
              </router-link> -->
                <!-- <router-link :to="{ name: 'register' }" class="forge-password" style="float: right; margin-right: 10px">
                学生账户注册
              </router-link> -->
              </a-form-model-item>
              <a-form-item style="margin: 44px 0px 0px 0px">
                <a-button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  class="login-button"
                  :loading="loginBtn"
                  @click.stop.prevent="handleSubmit"
                  :disabled="loginBtn"
                  >确定
                </a-button>
              </a-form-item>
            </a-form-model>

            <two-step-captcha
              v-if="requiredTwoStepCaptcha"
              :visible="stepCaptchaVisible"
              @success="stepCaptchaSuccess"
              @cancel="stepCaptchaCancel"
            ></two-step-captcha>
            <login-select-tenant ref="loginSelect" @success="loginSelectOk"></login-select-tenant>
            <third-login ref="thirdLogin"></third-login>
            <!-- <a-modal :visible="visible" width="310px" title="学生账户注册" @cancel="handleClose">
            <q-r-code-generator ref="child"></q-r-code-generator> 
            <div ref="qrcode" style="margin: 0 auto"></div>
            <template #footer>
              <a-button key="back" @click="handleClose">关闭</a-button>
            </template>
          </a-modal> -->
          </div>
          <div class="footer">
            <ul>
              <li>建议使用Chrome浏览器</li>
              <li>
                <a @click="link('ys')">隐私条款</a> <a @click="link('fw')">用户协议</a
                ><a @click="link('contact')">联系我们</a>
                <a @click="link('alteration')">教师注册</a>
              </li>
              <li>Copyright 北京毅力澎湃技术有限公司 版权所有 保留一切权利</li>
              <li><a href="https://beian.miit.gov.cn" target="blank">京ICP备15015852号-3</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN, ENCRYPTED_STRING } from '@/store/mutation-types'
import ThirdLogin from './third/ThirdLogin'
import LoginSelectTenant from './LoginSelectTenant'
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { getEncryptedString } from '@/utils/encryption/aesEncrypt'
import { timeFix } from '@/utils/util'
import GlobalFooter from '@/components/page/GlobalFooter'
import QRCodeGenerator from './QRCodeGenerator.vue'
import LoginAccount from './LoginAccount'
import LoginPhone from './LoginPhone'
import QRCode from 'qrcodejs2'
export default {
  components: {
    GlobalFooter,
    LoginSelectTenant,
    TwoStepCaptcha,
    ThirdLogin,
    LoginAccount,
    LoginPhone,
    QRCode,
  },
  data() {
    return {
      visible: false,
      QRCodeMsg: '',
      customActiveKey: 'tab1',
      rememberMe: true,
      loginBtn: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      encryptedString: {
        key: '',
        iv: '',
      },
      text: '',
      qrcode: null,
    }
  },
  created() {
    Vue.ls.remove(ACCESS_TOKEN)
    this.getRouterData()
    this.rememberMe = true
  },
  mounted() {
    //this.studentRegister()
  },
  methods: {
    handleClose() {
      this.visible = false
    },
    handleTabClick(key) {
      this.customActiveKey = key
    },
    gotoIndex() {
      this.$router.push({ path: '/user/index' })
    },
    handleRememberMeChange(e) {
      this.rememberMe = e.target.checked
    },
    /**跳转到登录页面的参数-账号获取*/
    getRouterData() {
      this.$nextTick(() => {
        let temp = this.$route.params.username || this.$route.query.username || ''
        if (temp) {
          this.$refs.alogin.acceptUsername(temp)
        }
      })
    },

    //登录
    handleSubmit() {
      this.loginBtn = true
      if (this.customActiveKey === 'tab1') {
        // 使用账户密码登录
        this.$refs.alogin.handleLogin(this.rememberMe)
      } else {
        //手机号码登录
        this.$refs.plogin.handleLogin(this.rememberMe)
      }
    },
    // 校验失败
    validateFail() {
      this.loginBtn = false
    },
    // 登录后台成功
    requestSuccess(loginResult) {
      window.localStorage.setItem('schoolIdCode', '')
      if (loginResult.tenantList.length == 1) {
        let schoolIdCode = loginResult.tenantList[0]['schoolIdCode']
        window.localStorage.setItem('schoolIdCode', schoolIdCode)
      }
      this.$refs.loginSelect.show(loginResult)
    },
    //登录后台失败
    requestFailed(err) {
      let description = ((err.response || {}).data || {}).message || err.message || '请求出现错误，请稍后再试'
      this.$notification['error']({
        message: '登录失败',
        description: description,
        duration: 4,
      })
      //账户密码登录错误后更新验证码
      if (this.customActiveKey === 'tab1' && description.indexOf('密码错误') > 0) {
        this.$refs.alogin.handleChangeCheckCode()
      }
      this.loginBtn = false
    },
    loginSelectOk() {
      console.log('success')
      this.loginSuccess()
    },
    //登录成功
    loginSuccess() {
      this.$nextTick(() => {
        this.$router.push({ path: '/dashboard/analysis' }).catch(() => {
          console.log('定位错误!')
          console.log('登录跳转首页出错,这个错误从哪里来的')
        })
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`,
        })
      })
    },

    stepCaptchaSuccess() {
      this.loginSuccess()
    },
    stepCaptchaCancel() {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    //获取密码加密规则
    getEncrypte() {
      var encryptedString = Vue.ls.get(ENCRYPTED_STRING)
      if (encryptedString == null) {
        getEncryptedString().then((data) => {
          this.encryptedString = data
        })
      } else {
        this.encryptedString = encryptedString
      }
    },
    link(str) {
        window.open(`/user/${str}`)
    },
  },
}
</script>
<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }
  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
.valid-error .ant-select-selection__placeholder {
  color: #f5222d;
}
.loginBox {
  width: 40%;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer {
    color: #a7a7a7;
    text-align: center;
    position: absolute;
    bottom: 5px;
    ul {
      li {
        padding: 2px;
      }
    }
    a {
      margin-right: 10px;
      color: #a7a7a7;
    }
  }
  .rightBox {
    padding: 0;
    width: 370px;
    min-height: 360px;
    border-radius: 15px;
    overflow: hidden;
    .title {
      min-width: 106px;
      height: 38px;
      font-size: 27px;
      font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
      margin-bottom: 19px;
    }
  }
}
.login_main {
  height: 100%;
  background: #fff;
}
.hlhs-p {
  font-size: 25px;
  color: #fff;
}
.hlhs-p2 {
  font-size: 16px;
  color: #fff;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #fff;
  font-size: 20px;
}
.ant-tabs-ink-bar-animated {
  display: none !important;
}
.logoinTitle {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  height: 100px;
  line-height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
}
.footerTools {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
.footerTools li {
  list-style: none;
  padding-left: 35px;
}
.login_footer {
  text-align: center;
}
.login_footer {
  position: fixed;
  bottom: 10px;
  width: 100%;
}
.login_footer div {
  margin-bottom: 5px;
}
::v-deep .ant-form-item-control {
  line-height: 0 !important;
}
.imgBox {
  width: 60%;
  height: 100%;
  max-width: 1167px;
  max-height: 1068px;
  position: relative;
  .img {
    width: 100%;
    height: 100%;
    max-width: 1167px;
    max-height: 1068px;
  }
  .img_top {
    position: absolute;
    top: 104px;
    left: 95px;
  }
}
ul li {
  list-style: none;
}
</style>