var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-settings-info-view" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { md: 24, lg: 16 } },
            [
              _c(
                "a-form",
                { attrs: { layout: "vertical" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "昵称" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "给自己起个名字" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "Bio" } },
                    [
                      _c("a-textarea", {
                        attrs: { rows: "4", placeholder: "You are not alone." }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "电子邮件", required: false } },
                    [
                      _c("a-input", { attrs: { placeholder: "exp@admin.com" } })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "加密方式", required: false } },
                    [
                      _c(
                        "a-select",
                        { attrs: { defaultValue: "aes-256-cfb" } },
                        [
                          _c(
                            "a-select-option",
                            { attrs: { value: "aes-256-cfb" } },
                            [_vm._v("aes-256-cfb")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "aes-128-cfb" } },
                            [_vm._v("aes-128-cfb")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "chacha20" } },
                            [_vm._v("chacha20")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "连接密码", required: false } },
                    [_c("a-input", { attrs: { placeholder: "h3gSbecd" } })],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "登录密码", required: false } },
                    [_c("a-input", { attrs: { placeholder: "密码" } })],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c("a-button", { attrs: { type: "primary" } }, [
                        _vm._v("提交")
                      ]),
                      _c(
                        "a-button",
                        { staticStyle: { "margin-left": "8px" } },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { style: { minHeight: "180px" }, attrs: { md: 24, lg: 8 } },
            [
              _c(
                "div",
                {
                  staticClass: "ant-upload-preview",
                  on: {
                    click: function($event) {
                      return _vm.$refs.modal.edit(1)
                    }
                  }
                },
                [
                  _c("a-icon", {
                    staticClass: "upload-icon",
                    attrs: { type: "cloud-upload-o" }
                  }),
                  _c(
                    "div",
                    { staticClass: "mask" },
                    [_c("a-icon", { attrs: { type: "plus" } })],
                    1
                  ),
                  _c("img", { attrs: { src: _vm.option.img } })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("avatar-modal", { ref: "modal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }