var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login_main" }, [
    _c(
      "div",
      {
        staticStyle: {
          height: "100%",
          display: "flex",
          "justify-content": "center",
          "align-items": "center"
        }
      },
      [
        _c("div", { staticClass: "imgBox" }, [
          _c("img", {
            staticClass: "img",
            attrs: {
              src: require("../../assets/newIndex/logo_left.png"),
              alt: "logo"
            }
          }),
          _c("img", {
            staticClass: "img_top",
            attrs: {
              src: require("../../assets/newIndex/footer_logo.png"),
              alt: "logo"
            },
            on: { click: _vm.gotoIndex }
          })
        ]),
        _c("div", { staticClass: "loginBox" }, [
          _c(
            "div",
            {
              staticClass: "main rightBox",
              staticStyle: {
                width: "100%",
                "min-height": "350px",
                display: "flex",
                "justify-content": "center",
                "align-items": "center"
              }
            },
            [
              _c(
                "div",
                { staticClass: "main rightBox" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("欢迎使用")]),
                  _c(
                    "a-form-model",
                    {
                      staticClass: "user-layout-login",
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleSubmit($event)
                        }
                      }
                    },
                    [
                      _c("login-account", {
                        ref: "alogin",
                        on: {
                          validateFail: _vm.validateFail,
                          success: _vm.requestSuccess,
                          fail: _vm.requestFailed
                        }
                      }),
                      _c(
                        "a-form-model-item",
                        [
                          _c(
                            "a-checkbox",
                            {
                              attrs: { "default-checked": "" },
                              on: { change: _vm.handleRememberMeChange }
                            },
                            [_vm._v("自动登录")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { staticStyle: { margin: "44px 0px 0px 0px" } },
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "login-button",
                              attrs: {
                                size: "large",
                                type: "primary",
                                htmlType: "submit",
                                loading: _vm.loginBtn,
                                disabled: _vm.loginBtn
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleSubmit($event)
                                }
                              }
                            },
                            [_vm._v("确定\n              ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.requiredTwoStepCaptcha
                    ? _c("two-step-captcha", {
                        attrs: { visible: _vm.stepCaptchaVisible },
                        on: {
                          success: _vm.stepCaptchaSuccess,
                          cancel: _vm.stepCaptchaCancel
                        }
                      })
                    : _vm._e(),
                  _c("login-select-tenant", {
                    ref: "loginSelect",
                    on: { success: _vm.loginSelectOk }
                  }),
                  _c("third-login", { ref: "thirdLogin" })
                ],
                1
              ),
              _c("div", { staticClass: "footer" }, [
                _c("ul", [
                  _c("li", [_vm._v("建议使用Chrome浏览器")]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.link("ys")
                          }
                        }
                      },
                      [_vm._v("隐私条款")]
                    ),
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.link("fw")
                          }
                        }
                      },
                      [_vm._v("用户协议")]
                    ),
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.link("contact")
                          }
                        }
                      },
                      [_vm._v("联系我们")]
                    ),
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.link("alteration")
                          }
                        }
                      },
                      [_vm._v("教师注册")]
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      "Copyright 北京毅力澎湃技术有限公司 版权所有 保留一切权利"
                    )
                  ]),
                  _vm._m(0)
                ])
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { href: "https://beian.miit.gov.cn", target: "blank" } },
        [_vm._v("京ICP备15015852号-3")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }